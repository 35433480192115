import React from 'react';
import { PageTitle, Row } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './List.module.scss';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface ListProps{
	Component?: any
}

const List = ({Component='div'}: ListProps) => {
	return (
		<Component styles={styles['solicitation-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12} className=''>
					<PageTitle.Small title={t('OZYMANDIAS.SOLICITATION.LIST_OF_PROCESSES')} />
				</Row.Item>
			</Row>
		</Component>
		
	)
}

export default List;