import { HashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/templates/App/App';

import './index.scss';

declare global {
    interface Window { 
        renderOzymandias: any;
        unmountOzymandias: any;
    }
}

window.renderOzymandias = (containerId: string, history: HashHistory, store: any): Boolean => {
    ReactDOM.render(
        <App history={history} />,
        document.getElementById(containerId),
    );
    return true
};

window.unmountOzymandias = (containerId: string):Boolean => {
    const domElement =  document.getElementById(containerId) as Element;
    ReactDOM.unmountComponentAtNode(domElement);
    return true
};

if (document.getElementById('ozymandias-container') == null) {
    ReactDOM.render(
        <React.StrictMode>
            <App showMenu/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
