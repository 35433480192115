export const TOKEN_KEY = "TOKEN_KEY";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const setLogin = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token);
};

export const setLogout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
};