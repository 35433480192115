import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { fetchAuditsSettings, updateAuditsSettings } from '../../../services/audit.services';

import { PageTitle, Row, Tabs, Panel, Button, Select, Input, Loading, Table, TimeSelect, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { MONTH_OF_YEAR_EN } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import styles from './Setting.module.scss';
import { InstanceConfiguration } from '../../../models/InstanceConfiguration';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface SettingProps {
	Component?: any
}

enum MetaTypeEnum{
    'absenteeism' = 'metaAbsenteeism',
    'infraction' = 'metaInfraction',
	'overtime' = 'metaOvertime',
}

const Setting = ({Component='div'}: SettingProps) => {
	const atualYear = new Date().getUTCFullYear();

	//Hook para estado do componente
	const [year,setYear] = useState(atualYear);
	const [currentStatus,setCurrentStatus] = useState<string>('waiting');
	const [updateProgress,setUpdateProgress] = useState<boolean>(false);
	const [operationFeedback,setOperationFeedback] = useState<any>(null);
	const [auditSettings,setAuditSettings] = useState<InstanceConfiguration | null>(null);

	const types: {[key: string]: string} = {
		'OVERTIME':t('GENERIC.TEXT.OVERTIME',undefined),
		'DATABASE':t('GENERIC.TEXT.HOUR_BANK',undefined)
	}

	 //Efeitos do componente
	useEffect(() => {
		(async () => {
			try{
				const jsonReturned =  await fetchAuditsSettings();
				if(jsonReturned){
					setAuditSettings(jsonReturned)
					setCurrentStatus('success');
				}else{
					setCurrentStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();

    }, []);

	const renderSelectYear = () => {
        const options = [];

        for (let index = atualYear-3; index < atualYear+2; index++) {

            options.push({key:index.toString(), value: index.toString(), selected: atualYear===index})

        }

        return(
            <Select className="select-year" large name="year" options={options} value={year.toString()} title={t('GENERIC.TEXT.YEAR',undefined)}
                handleChange={(selected)=> setYear(selected)}/>
        );
    }

	const updateSettings = async () => {
		if(!updateProgress){
			setUpdateProgress(true);
			setOperationFeedback(null);
			let jsonReturned =  await  updateAuditsSettings(auditSettings);
			if(jsonReturned){
				setUpdateProgress(false);
				setOperationFeedback({message: t('OZYMANDIAS.SETTINGS.UPDATED_SETTINGS',undefined), status: 'success'});

			}else{
				setCurrentStatus('error');
				setOperationFeedback({message: t('OZYMANDIAS.SETTINGS.PROBLEM_FOUND_TO_UPDATE',undefined), status: 'error'});
			}
		}
	}

    const renderInputAllMonthsChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        const splitName = name.split('-');
        const month = MONTH_OF_YEAR_EN[parseInt(splitName[1])];
		const metaType: MetaTypeEnum | undefined = Object.values(MetaTypeEnum).find(x => x === splitName[0]);
		let asettings: any = {...auditSettings};
		
		if(value.trim() ==='' || value.indexOf('-') !== -1){
			value = '0';
		}
		asettings[metaType!][year][month] = parseInt(value);
		setAuditSettings({...auditSettings, ...asettings})
    }

    const renderInputAllMonths = (type: MetaTypeEnum) => {
        return(
			<Table className={styles['table-metas']}>
				<Table.Body>
					{moment.months().map((month,index) => {
						return (
							<Table.BodyItem key={month+index}>
								<Table.BodyItem.Item>
									{`${month} de ${year}`}
								</Table.BodyItem.Item>
								<Table.BodyItem.Item>
									<Input
										name={`${type}-${index}`}
										value={auditSettings?.[type][year][MONTH_OF_YEAR_EN[index]]}
										//title={`${month} de ${year}`}
										type="number"
										key={`${type}-${index}`}
										fullWidth
										handleChange={(event)=> renderInputAllMonthsChangeValue(event)}
									/>
								</Table.BodyItem.Item>
							</Table.BodyItem>
								
						);
					})}
				</Table.Body>
			</Table>
           
        );
    }

	const renderScheduledTimes = () => {
		let scheduledTime = auditSettings?.scheduledTime===''?[]:auditSettings?.scheduledTime?.split(',');
		let scheduledTimeStr = '';
		let maxScheduledTime = 2;
		return (
			<TimeSelect
				primary
				timesSelected={scheduledTime}
				handleChange={
					(values:any) => {
						if(values['value'].length <= maxScheduledTime && values['value'].length > 0){
							scheduledTimeStr = values['value'].join(',');
						}else if(values['value'].length === 0){
							scheduledTimeStr = '';
						}else{
							scheduledTimeStr = scheduledTime!.join(',')
							if(values['value'].length > maxScheduledTime){
								alert(t('OZYMANDIAS.SETTINGS.YOU_CAN_SELECT_HOURS',undefined));
							}
						}
						setAuditSettings({...auditSettings!, scheduledTime: scheduledTimeStr});
							
					}
				}/>
		)
		
	}

	const renderFeedback = () =>{
		if(operationFeedback !== null){
			if(operationFeedback.status==='error'){
				return(
					<Toast icon='error' 
						message={operationFeedback.message}
						title={t('GENERIC.TEXT.ATTENTION',undefined)}
						danger 
						positionTopRight 
						showTime={5000}/>
				)
			}else{
				return(
					<Toast icon='ok' 
						message={operationFeedback.message}
						title={t('GENERIC.TEXT.SUCCESS',undefined)}
						success 
						positionTopRight 
						showTime={5000}/>
				)
			}
		}else{
			<></>
		}
	}

	return (
		<Component className={styles['audit-settings-rt']}>
			{renderFeedback()}
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('GENERIC.TEXT.SETTINGS',undefined)} />
				</Row.Item>

				{(currentStatus === 'waiting') &&
					<Row.Item lg={12} md={12} sm={12}>
						<Panel defaul>
							<Loading text={t('OZYMANDIAS.SETTINGS.LOADING_SETTINGS')}/>
						</Panel>
					</Row.Item>
				}
			
				{currentStatus === 'error' &&
					<Row>
						<Row.Item lg={12} md={12} sm={12}>
							<Panel centeredContent>
								<h4>{t("OZYMANDIAS.SETTINGS.PROBLEM_RETURNING_INFORMATION")}</h4>
							</Panel>
						</Row.Item>
					</Row>
				}

				{currentStatus === 'success' && 
					<Row.Item sm={12} md={12} lg={12}>
						<Panel className={styles['panel']}>
							<>
								<Tabs activeTab={0} handleTabChange={()=>{}}>
									<Tabs.Item title={t('OZYMANDIAS.SETTINGS.LEGAL_PARAMETERS')}>
										<Row>
											<Row.Item lg={3} md={3} sm={3}>
												<Select
													
													name="calculationTime"
													options={[
														{key:'OVERTIME', value: t('GENERIC.TEXT.OVERTIME'), selected: auditSettings?.calculationType==='OVERTIME'},
														{key:'DATABASE', value: t('GENERIC.TEXT.HOUR_BANK'), selected: auditSettings?.calculationType==='DATABASE'}
													]}
													value={types[auditSettings!.calculationType]}
													title={t('OZYMANDIAS.SETTINGS.HOURS_CALCULATION_FORM')}
													handleChange={(selected)=> {
														setAuditSettings({...auditSettings!, calculationType:selected});
													}}/>
											</Row.Item>
											{auditSettings?.calculationType==='DATABASE' &&
												<Row.Item lg={4} md={4} sm={4}>
													<Input
														type="number"
														value={auditSettings.overtimeLimit}
														title={t('OZYMANDIAS.SETTINGS.MAXIMUM_NUMBER_OF_EXTRA_MINUTES')}
														fullWidth
														handleChange={(event)=> {
															setAuditSettings({...auditSettings!, overtimeLimit:parseInt(event.target.value)});
														}}/>
												</Row.Item>
											}
										</Row>
									</Tabs.Item>
									<Tabs.Item title={t('OZYMANDIAS.SETTINGS.GOALS')}>
										<Row>
											<Row.Item lg={2} md={2} sm={2}>
												{renderSelectYear()}
											</Row.Item>
										</Row>
										<Row>
											<Row.Item lg={4} md={4} sm={4}>
												<span className={styles['subtitle']}>{t('OZYMANDIAS.SETTINGS.TOTAL_MINUTES_OF_OVERTIME')}</span>
												{renderInputAllMonths(MetaTypeEnum.overtime)}
											</Row.Item>
											<Row.Item lg={4} md={4} sm={4}>
												<span className={styles['subtitle']}>{t('OZYMANDIAS.SETTINGS.TOTAL_MINUTES_OF_ABSENTEEISM')}</span>
												{renderInputAllMonths(MetaTypeEnum.absenteeism)}
											</Row.Item>
											<Row.Item lg={4} md={4} sm={4}>
												<span className={styles['subtitle']}>{t('OZYMANDIAS.SETTINGS.MAXIMUM_NUMBER_OF_INFRACTIONS')}</span>
												{renderInputAllMonths(MetaTypeEnum.infraction)}
											</Row.Item>
										</Row>
									</Tabs.Item>
									<Tabs.Item title={t('OZYMANDIAS.SETTINGS.AUTOMATIC_ROUTINES')}>
										<span className={styles['subtitle']}>{t('OZYMANDIAS.SETTINGS.SELECT_TIMES_AUDITOR_PERFORM')}</span>
										{renderScheduledTimes()}
									</Tabs.Item>
								</Tabs>
								<Component className={styles['actions']}>
									<Button title={t('GENERIC.BUTTON.CANCEL.TEXT')} action={()=>{window.location.href='#/auditsMF'}} />
									<Button loading={updateProgress} icon='save' title={t('GENERIC.BUTTON.SAVE.TEXT')} primary action={()=>updateSettings()} />
								</Component>
							</>
						</Panel>
					</Row.Item>
				}
			</Row>
			
		</Component>
	)
}

export default Setting;