
import API from './core';

export const fetchAuditsAbstract = (data:any, params:any) => API.post(`rest/audits/results/getAbstract`, JSON.stringify(data), { params: params, headers: { 'Content-Type': 'application/json' } });
export const fetchAuditsAbstractYear = (data: any, params: any) => API.post(`rest/audits/results/getAbstractYear`, JSON.stringify(data), { params: params, headers: { 'Content-Type': 'application/json' } });
export const fetchAuditsSettings = async (params?:any) => {
	try{
		return await API.get(`rest/audits/instances/configurations`, {params})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
				}, error => {
					return null
				}
			);
	}catch(error){
		console.log(error)
		return error;
	}
};
export const updateAuditsSettings = async (data:any) => {
	try{
		
		return await API.post(`rest/audits/instances/configurations`, 
					JSON.stringify(data), 
					{ headers: { 'Content-Type': 'application/json' }})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
					
				}, error => {
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const fetchPdfInconsistencies = async (data:any, params:any) => {
	try{
		
		return await API.post(`rest/audits/results/getInconsistenciesPdf`, 
					JSON.stringify(data), 
					{ params: {...params, exportType:'pdf'}, headers: { 'Content-Type': 'application/json' }, 
					responseType: 'arraybuffer' })
			.then(
				response => {
					if(response.status !== 200){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const fetchPdfInfractions = async (data:any, params:any) => {
	try{
		
		return await API.post(`rest/audits/results/getInfractionsPdf`, 
					JSON.stringify(data), 
					{ params: {...params, exportType:'pdf'}, headers: { 'Content-Type': 'application/json' }, 
					responseType: 'arraybuffer' })
			.then(
				response => {
					if(response.status !== 200){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const fetchCsvInfractions = async (data:any, params:any) => {
	try{
		
		return await API.post(`rest/audits/results/getInfractionsCsv`, 
					JSON.stringify(data), 
					{ params: {...params, exportType:'csv'}, headers: { 'Content-Type': 'application/json' }, 
					responseType: 'arraybuffer' })
			.then(
				response => {
					if(response.status !== 200){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const fetchCsvInconsistencies = async (data:any, params:any) => {
	try{
		
		return await API.post(`rest/audits/results/getInconsistenciesCsv`, 
					JSON.stringify(data), 
					{ params: {...params, exportType:'csv'}, headers: { 'Content-Type': 'application/json' }, 
					responseType: 'arraybuffer' })
			.then(
				response => {
					if(response.status !== 200){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}