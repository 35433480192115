
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faPlus,
	faSave,
	faTimes,
	faFilePdf,
	faFileCsv,
} from '@fortawesome/free-solid-svg-icons'

import {
	faClock as fasClock,
	faUserSlash as fasUserSlash,
	faUserClock as fasUserClock,
	faExclamationTriangle as fasExclamationTriangle,
	faBell as fasBell,
	faCalculator as fasCalculator,
	faCheckCircle as fasCheckCircle,
	faSpinner as fasSpinner
} from '@fortawesome/pro-solid-svg-icons';

import {
    faCheck as falCheck, 
	faClock as falClock, 
} from '@fortawesome/pro-light-svg-icons';

library.add(
	fasClock, fasUserSlash, fasUserClock, fasExclamationTriangle, fasBell, fasCalculator, fasCheckCircle, 
	fasSpinner,

    falCheck, falClock, 

	faPlus, faTimes, faSave, faFilePdf, faFileCsv
);

