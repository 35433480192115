import React from 'react';
import ApplicationRouter from '../../../router';

import '../../../configs/awesomeFontLibrary';

import { Sidebar } from '@optimuminterfaces/revex-react-components/dist/components/index';

import './App.scss';
import styles from './App.module.scss';
export interface AppProps {
    history?: any;
    showMenu?: boolean,
    Container?: any
};

const renderSidebar = () => {
	return (
		<Sidebar>
			<Sidebar.Menu name="Dashboard" icon="clock" url="#/auditsMF/"/>
			<Sidebar.Menu name="ADI" icon="clock">
				<Sidebar.SubMenu 
					icon="clock" 
					url="#/auditsMF" 
					title="Análises" 
					subtitle="Acompanhar os incidentes/inconsistencias" />
                <Sidebar.SubMenu 
					icon="clock" 
					url="#/auditsMF/solicitations" 
					title="Solicitações" 
					subtitle="Acompanhar o status do processamento dos incidentes" />
				<Sidebar.SubMenu 
					icon="clock" 
					url="#/auditsMF/settings" 
					title="Configurações" 
					subtitle="Customização do comportamente da Analise de Incidentes" />										
			</Sidebar.Menu>			
		</Sidebar>
	)
};

function App({ history, showMenu, Container='div' }: AppProps) {
    return (
        <div className={`${styles['app']} ${showMenu ? styles['sidebar'] : ''} ozymandias-app`}>
            {showMenu &&
                <Container className={styles['container-sidebar']}>
                    {renderSidebar()}
                </Container>
            }

            <Container className={styles['container-body']}>
				<ApplicationRouter history={history}/>
			</Container>
        </div>
    );
}

export default App;