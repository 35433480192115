import React from 'react';
import { Route, HashRouter as Router, Redirect } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import Dashboard from './components/pages/Dashboard/Dashboard';
import {default as SolicitationList} from './components/pages/Solicitation/List/List';
import {default as Setting} from './components/pages/Setting/Setting';

const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
	history : HashHistory
}

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {
    return(
        <Router >
            <Route exact path="/auditsMF"> 
				<Dashboard /> 
			</Route>
			<Route exact path="/auditsMF/solicitations"> 
				<SolicitationList /> 
			</Route>
			<Route exact path="/auditsMF/settings"> 
				<Setting /> 
			</Route>
        </Router>
    );
}

export default ApplicationRouter;