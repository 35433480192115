import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Panel, Row, Modal, 
		AlertTiny, Tabs, Slider, 
		AvatarNameValue, BoxInformation, ChartBar, 
		Calendar, ChartPie, Loading,
		BarIndicator, PageTitle, BoxMeta, BoxMessage, 
		Select, ChartMultiBar } from '@optimuminterfaces/revex-react-components/dist/components/index';

import TableListPagination from '../../organism/TableListPagination/TableListPagination';

import { fetchAuditsAbstract, fetchAuditsAbstractYear, fetchCsvInconsistencies, fetchCsvInfractions, fetchPdfInconsistencies, fetchPdfInfractions } from '../../../services/audit.services';

import { isObjectEmpty } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { downloadCsv, downloadPdf } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import { sortAlphabeticallyByKey, sortByKeys } from '@optimuminterfaces/revex-react-components/dist/utils/SortUtil';
import { abbreviation } from '@optimuminterfaces/revex-react-components/dist/utils/StringUtils';
import { colorChart } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';
import { formatLocalDate, formatLocalTime, convertMinutesToTimeStr, getMonthHumanized } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import imageUser from '../../../assets/images/avatar.png';

import styles from './Dashboard.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index'

interface DashboardProps{
	Container?: any
}

enum ResultTypeEnum {
    managed = "resultsManaged",
    contract = "resultsContract"
}

interface ResultModel{
	employeeIncidents?: any[],
	abstract?: any,
	inconsistenciesPerCategory?: any,
	infractionsPerCategory?: any,
	infractionsPerDay?: any,
	inconsistenciesPerDay?: any,
	overtimePerDay?: any,
	absenteeismPerDay?: any,
	incidents?: any,
	overtime?: any,
	absenteeism?: any,
	database?: any,
	databasePerDay?: any
}

interface AnalyticsModel{
	status?: string,
	statusYear?: string,
	resultsManaged?: ResultModel,
	allEmployeesPis?: any,
	resultsContract?: ResultModel,
	metaOvertime?: number,
	metaAbsenteeism?: number,
	metaInfraction?: number,
	metaDatabase?: number,
	configMaxWork?: number,
	configMaxSunday?: number,
	configCalculationType?: string,
	configOvertimeLimit?: number,
	filters?: any,
	abstractYear?: any,
	infractionTypes?: string[],
	inconsistencyTypes?: string[]

}
interface AnalyticsYearModel{
	resultsManaged?: {inconsistenciesYearPerMonthPerCategory?: any, infractionsYearPerMonthPerCategory?: any},
	resultsContract?: {inconsistenciesYearPerMonthPerCategory?: any, infractionsYearPerMonthPerCategory?: any}

}

interface EmployeeModel{
	employeeId?: string,
	employeeName?: string,
	employeeOccupation?: string,
	employeeOccupationId?: string,
	employeePis?: string,
	cpf?: string,
	amountOfInfractions?: any,
	amountOfInconsistencies?: any
}

const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number ) => {
	
	if(!columnValue){
		return '-';
	}

    switch(columnName){
        case 'date':
            return formatLocalDate(columnValue, true, 'YYYY-MM-DD', undefined);

        case 'value':
            return convertMinutesToTimeStr(columnValue);

        case 'month':
            try{
                if(columnValue.match(/\d{4}-\d{2}/)){
                    return moment(columnValue, ['YYYY-MM']).locale('pt-br').format('MMMM / YYYY');
                }
            }catch(e){}
            return columnValue;

        case 'dailyScaleDay':
            try{
                const match = columnValue.match(/(\d{2}:\d{2})-\[(\d{2}:\d{2})-(\d{2}:\d{2});(\d+)\]-(\d{2}:\d{2})(?:\|(\d{2}:\d{2})-(\d{2}:\d{2}))?/);
                if(!!match && match.length === 8){
                    let msg = `${match[1]}-${match[2]}-${match[3]}-${match[5]} / ${t('GENERIC.TEXT.INTERVAL')} ${match[4]} min`;
                    
                    // check for hour absence
                    if(!!match[6] && !!match[7]){
                        msg += ` / ${t('GENERIC.TEXT.ABSENCE')} ${match[6]}-${match[7]}`;
                    }

                    return msg;
                }
            }catch(e){}

            return columnValue;
            
        case 'details':
        case 'scaleDetails':
            if(columnName === 'scaleDetails'){
                if(!columnValue){
                    return t('SCALE.TEXT.LEGEND.NO_SCALE');
                }else if(String(columnValue) === 'Folga'){
                    return t('SCALE.TEXT.LEGEND.DAY_OFF');
                }
            }

            let values = '';
            let separator = '';
			let intervalo = '';
			let absenceHours = '';
            
            try{
                const dailyScaleDay = columnValue.join('-');
                const match = dailyScaleDay.match(/(\d{2}:\d{2})-\[(\d{2}:\d{2})-(\d{2}:\d{2});(\d+)\]-(\d{2}:\d{2})(?:\|(\d{2}:\d{2})-(\d{2}:\d{2}))?$/);
                if(!!match && match.length === 8){
                    let msg = `${match[1]}-${match[2]}-${match[3]}-${match[5]} / ${t('GENERIC.TEXT.INTERVAL')} ${match[4]} min`;
                    
                    // check for hour absence
                    if(!!match[6] && !!match[7]){
                        msg += ` / ${t('GENERIC.TEXT.ABSENCE')} ${match[6]}-${match[7]}`;
                    }

                    return msg;
                }
            }catch(e){}

            columnValue.forEach((value:string) => {
                const duplicated = value.match(/^([SE]\d{1,2}) (\d{2}:\d{2})(:d{2})*/);
                if(duplicated){
                    separator = ' | ';
                    values += duplicated[0].replace(' ', '-') + separator;
                }else if(value.indexOf(':') !== -1){
                    separator = '-';
					
					//É horario flexível [HH:MM-HH:MM;MM]
					if(value.match(/\[(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9];[0-9]?[0-9][0-9]\]$/g)){
						const flex = value.match(/(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/g);
						
						flex?.forEach((f,index) => {
							values +=  formatLocalTime(f) + separator;
						})

						intervalo = value.split(';')[1].replace(']','');
						
					}else if(value.match(/\|\d{2}:\d{2}-\d{2}:\d{2}(;\d{2}:\d{2}-\d{2}:\d{2})*/)){
                        absenceHours = value;
                    }else if(value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)){
						const inter = value.split(' ');
						values += formatLocalDate(inter[0], true, 'YYYY-MM-DD', undefined) + separator + formatLocalTime(inter[1]) + ' ';
					}else{
                    	values += formatLocalTime(value) + separator;
					}
                }else{
                    separator = ' ';
                    values += formatLocalDate(value, true, 'YYYY-MM-DD', undefined) + separator;
                }
            });

            let msg = values.substring(0, values.length - separator.length);
            
			if(intervalo !== ''){
			    msg += ` / ${t('GENERIC.TEXT.INTERVAL')} ${intervalo} min`;
			}

            if(absenceHours !== ''){
			    msg += ` / ${t('GENERIC.TEXT.ABSENCE')} ${absenceHours.substring(1)}`;
			}

            return msg;

        default:
            return columnValue;
    }
};

const buildSelectOptions = (employeeList:any, filters: { employees: any; employeesId?: any[] ; occupationsId?: any; }) => {
    try{
        const { employees, employeesId, occupationsId} = filters;
        if(employees instanceof Array){
            let idOptions = [], occupationOptions: { key: any; value: any; selected: boolean; }[] = [], listOccupations: {id: string, name: string}[] = [];
            employees.forEach((employee: EmployeeModel) => {
                if(!!employee.employeeId){
                    idOptions.push({ key: employee.employeeId,
                        value: (employee.employeeName || employee.employeeId),
                        selected: employeesId!.includes(employee.employeeId)
                    });
                }

                if(!!employee.employeeOccupation){
                    listOccupations.push({
						id: employee.employeeOccupationId!,
						name: employee.employeeOccupation
					});
                }
            });

            if(idOptions.length === 0){
                idOptions.push({ key: 'none', value: t('OZYMANDIAS.DASHBOARD.EMPTY_LIST'), selected: false });
            }
			try{
			occupationOptions = Array.from(new Set(listOccupations.map(s => s.id))).map(id => ({
					key: id,
					selected: occupationsId.includes(id),
					value: listOccupations.find(s => s.id === id)!.name
				}));
			}catch(e){
				console.log(e);
			}

            if(occupationOptions.length === 0){
                occupationOptions.push({ key: 'none', value: t('OZYMANDIAS.DASHBOARD.EMPTY_LIST'), selected: false });
            }

            return {
                idValue: t('GENERIC.TEXT.EMPLOYEES'),
                idOptions: idOptions,
                employeesId: employeesId,
                occupationValue: t('OZYMANDIAS.DASHBOARD.GROUPS'),
                occupationOptions: occupationOptions,
                occupationsId: occupationsId
            };
        }
    }catch(e){};

    return {};
};

const downloadIncidentsPdf = async (incidentsType: string, data: any, params:any, callBack:Function) => {
    if(incidentsType === 'inconsistencies'){
		let jsonReturned =  await  fetchPdfInconsistencies(data, params);
		if(jsonReturned){
			callBack({ status: 'success', data: jsonReturned });
		}else{
			callBack({ status: 'error' });
		}
        
    }else{
		let jsonReturned =  await  fetchPdfInfractions(data, params);
		if(jsonReturned){
			callBack({ status: 'success', data: jsonReturned });
		}else{
			callBack({ status: 'error' });
		}

    }
};

const downloadIncidentsCsv = async (incidentsType: string, data: any, params:any, callBack:Function) => {
    if(incidentsType === 'inconsistencies'){
		let jsonReturned =  await  fetchCsvInconsistencies(data, params);
		if(jsonReturned){
			callBack({ status: 'success', data: jsonReturned });
		}else{
			callBack({ status: 'error' });
		}
        
    }else{
		let jsonReturned =  await  fetchCsvInfractions(data, params);
		if(jsonReturned){
			callBack({ status: 'success', data: jsonReturned });
		}else{
			callBack({ status: 'error' });
		}

    }
};

const Dashboard = ({Container='div'}: DashboardProps) => {


	//Hook para estado do componente
	const [auditorAnalytics, setAuditorAnalytics] = useState<AnalyticsModel>();
	const [auditorAnalyticsYear, setAuditorAnalyticsYear] = useState<AnalyticsYearModel>();
	const [currentStatus,setCurrentStatus] = useState<string>('waiting');
    const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(0);
    const [dataInformation, setDataInformation] = useState(null);
    const [modalIncidentsPerEmployee, setModalIncidentsPerEmployee] = useState<{show: boolean, 
																				infractionsList?: any[], 
																				inconsistenciesList?: any[], 
																				employeeId?: string, 
																				employeePis?: string, 
																				employeeName?: string}>({ show: false, infractionsList: [],  inconsistenciesList: [], employeeName:'', employeePis: '' });
    const [modalOvertime, setModalOvertime] = useState<{show: boolean, name?: string | null}>({ show: false});
    const [modalDatabase, setModalDatabase] = useState<{show: boolean, name?: string | null}>({ show: false});
    const [modalAbsenteeism, setModalAbsenteeism] = useState<{show: boolean, name?: string | null}>({ show: false});
    const [filters, setFilters] = useState({
        startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        selectedDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
    });
    const [selectFilters, setSelectFilters] = useState<{employeesId?: any, 
														occupationsId?: any, 
														idOptions?: any, 
														occupationOptions?: any,
														idValue?: any, 
														occupationValue?: any }>({});
    const [lastSelectFilters, setLastSelectFilters] = useState<{employeesId?: any, occupationsId?: any}>({ 'employeesId': null, 'occupationsId': null });

    //Hook to state that set the current type of results being reandered
    const [resultTypeKey, setResultTypeKey] = useState<ResultTypeEnum>(ResultTypeEnum.managed);

    //Hook to block double click on buttons
    const [buttonsLoading, setButtonsLoading] = useState<boolean>(false);

    //Hook to set page manually on loading
    const [forcePageLoading, setForcePageLoading] = useState(false);

	const URL_MICRO_FRONT = process.env.REACT_APP_URL;

    /*const inconsistenciesTypes: string[] = ['Possível Infração','Erro no registro','Ponto em desacordo com a Escala','Horário de intervalo diferente do planejado'];
    const infractionsTypes = ['Trabalhar mais de 2 domingos consecutivos',
                                'Horas extras indevidas',
                                'Interjornada',
                                'Mais de 6 dias consecutivos',
                                'Horário de intervalo indevido'];*/

	//State para controle caso remova 
	const [loadData, setLoadData] = useState(new Date());

	const getAuditPerType = () => {
		if(resultTypeKey===ResultTypeEnum.managed){ 
			return auditorAnalytics?.resultsManaged;
		}else{
			return auditorAnalytics?.resultsContract;
		}

		
	}

    const sliderHandleClick = (employee: EmployeeModel) =>{
        let inconsistenciesList = [];
        let infractionsList = [];
        try{
            inconsistenciesList = getAuditPerType()!['incidents']['inconsistencies'].filter((e:any) => e['employeeId'] === employee.employeeId);
        }catch(e){}

        try{
            infractionsList = getAuditPerType()!['incidents']['infractions'].filter((e:any) => e['employeeId'] === employee.employeeId);
        }catch(e){}

        setModalIncidentsPerEmployee({
            show: true, ...employee,
            infractionsList: infractionsList,
            inconsistenciesList
        });
    };

    /*useEffect(() => {
        if(!!auditorAnalytics!.configMaxSunday && !!auditorAnalytics!.configMaxWork){
            setInfractionsTypes([
                `Trabalhar mais de ${auditorAnalytics!.configMaxSunday} domingos consecutivos`,
                'Horas extras indevidas',
                'Interjornada',
                `Mais de ${auditorAnalytics!.configMaxWork} dias consecutivos`,
                'Horário de intervalo indevido'
            ]);
        }
    }, [auditorAnalytics!.configMaxWork, auditorAnalytics!.configMaxSunday]);*/

    useEffect(() => {
        const firstSelectFilters = buildSelectOptions(getAuditPerType()?.employeeIncidents, auditorAnalytics?.filters);
        setLastSelectFilters({ employeesId: firstSelectFilters.employeesId, occupationsId: firstSelectFilters.occupationsId })
        setSelectFilters(firstSelectFilters);
    }, [getAuditPerType()?.employeeIncidents]);
	

	const reloadAbstractData = useCallback((startDate?: string, endDate?: string) => {
		setCurrentStatus('waiting');
        let jsonData: any = {};
        try{
            const employeesId = selectFilters.employeesId;
            if(((employeesId instanceof Array) && employeesId.length> 0)){
                jsonData.employeesId = employeesId;
            }

            const occupationsId = selectFilters.occupationsId;
            if(((occupationsId instanceof Array) && occupationsId.length> 0)){
                jsonData.occupationsId = occupationsId;
            }
        }catch(e){}
		const fetchAnalyze = async (data:any, params: any) => {
			fetchAuditsAbstract(data, params).then(
				response => {
					if(response.data.status === 'ERROR'){
						setCurrentStatus('error');
					}else{ 
						setAuditorAnalytics({...auditorAnalytics, ...response.data.data});
						setCurrentStatus('success');
					}
					
				}, error => {
					setCurrentStatus('error');
				}
			);
		}

		const fetchAnalyzeYear =  async (data:any, params: any) => {
			
			fetchAuditsAbstractYear(data, params).then(
				response => {
					if(response.data.status === 'ERROR'){
						setCurrentStatus('error');
					}else{
						setAuditorAnalyticsYear({...auditorAnalyticsYear, ...response.data.data});
					}
					
				}, error => {
					setCurrentStatus('error');
				}
			);
		}

        if(!!startDate && !!endDate){
            fetchAnalyze(jsonData, { startDate: startDate, endDate: endDate });
            fetchAnalyzeYear(jsonData, { year: startDate.substring(0,4), endDate: undefined });
        }else{
            fetchAnalyze(jsonData, { ...filters });
            fetchAnalyzeYear(jsonData, { ...filters, year: filters.startDate.substring(0,4), startDate: undefined, endDate: undefined, selectedDate: undefined});
        }
    }, [auditorAnalytics,auditorAnalyticsYear,filters,selectFilters]);

    useEffect(() => {
        reloadAbstractData();
    }, []);

	useEffect(() => {
		if(currentStatus === 'success'){
        	reloadAbstractData();
		}
    }, [loadData]);

    useEffect(() => {
        let dateTextFormat = 'MMMM YYYY';
        if( (filters.selectedDate === filters.startDate) && (filters.selectedDate === filters.endDate) ){
            dateTextFormat = 'DD [de] MMMM YYYY';
        }

        setDataInformation(formatLocalDate(filters.selectedDate, false, 'YYYY-MM-DD', dateTextFormat));
    }, [filters]);

    const handleFiltersChange = ({ name, value }: {name: string, value: any}) => {
        const change = (value: string, list: any[]) => {
            let val = '';
            let arr: any[] = [];
            const listChanged = list.map(option => {
                if(option.key === value){
                    val = option.value;
                }

                const newOption = {
                    key: option.key,
                    value: option.value,
                    selected: (option.key === value ? !option.selected : option.selected)
                };

                if(newOption.selected){
                    arr.push(newOption.key);
                }

                return newOption;
            });
            return [listChanged, arr, val];
        };

        //handle the events triggered by the components
        switch(name){
            case 'selectedId':
                try{
                    const [idOptions, employeesIdSelected] = change(value, selectFilters.idOptions);
					
                    setSelectFilters({ ...selectFilters,
                        idOptions: idOptions, employeesId: employeesIdSelected
                    });
                }catch(e){}
                break;

            case 'selectedOccupation':
                try{
                    const [occupationOptions, occupationsSelected] = change(value, selectFilters.occupationOptions);

                    setSelectFilters({ ...selectFilters,
                        occupationOptions: occupationOptions, occupationsId: occupationsSelected
                    });
                }catch(e){}
                break;

            case 'deleteKey':
                setTimeout(()=> {setLoadData(new Date())}, 500);
                break;

            case 'closeSelect':
                try{
                    const { employeesId: lastEmployeesId, occupationsId: lastOccupationsId } = lastSelectFilters;
                    const { employeesId: currentEmployeesId, occupationsId: currentOccupationsId } = selectFilters;
					
                    if((lastEmployeesId.length !== currentEmployeesId.length) || !(lastEmployeesId.every((employeesId:any) => currentEmployeesId.includes(employeesId)))){
                        setLastSelectFilters({ ...lastSelectFilters, employeesId: currentEmployeesId });
                        //fetch the data
                        reloadAbstractData();
                    }else if((lastOccupationsId.length !== currentOccupationsId.length) || !(lastOccupationsId.every((occupation:any) => currentOccupationsId.includes(occupation)))){
                        setLastSelectFilters({ ...lastSelectFilters, occupationsId: currentOccupationsId });
                        //fetch the data
                        reloadAbstractData();
                    }

                }catch(e){}
                break;

            case 'selectedRangeDate':
                try{
                    setFilters({...filters, startDate: value.startDate, endDate: value.endDate, selectedDate: value.startDate });
                    reloadAbstractData(value.startDate, value.endDate);
                }catch(e){}
                break;

            /*case 'calendarDates':
                try{
                    const startDate = filters.startDate;
                    const endDate = filters.endDate;

                    if(startDate !== value[1] || endDate !== value[2]){
                        if(startDate !== endDate){
                            setFilters({...filters, startDate: value[1], endDate: value[2], selectedDate: value[1] });
                            reloadAbstractData(value[1], value[2]);
                        }else{
                            let firstDate = moment(value[1]);
                            let endDate = moment(value[2]);
                            let selectedDate = moment(filters.selectedDate);
                            let startDateCalen = moment(selectedDate).startOf('month');
                            let endDateCalen = moment(selectedDate).endOf('month');

                            if(firstDate.isBefore(startDateCalen) || endDate.isAfter(endDateCalen)){
                                setFilters({...filters, startDate: value[1], endDate: value[2], selectedDate: value[1] });
                                reloadAbstractData(value[1], value[2]);
                            }
                        }
                    }
                }catch(e){}
                break;*/

            case 'downloadPdf':
                try{
                    setButtonsLoading(true);
                    const callbackDownloadPdf = ({ status, data }: {status: string, data: any} ) => {
                        if(status === 'success'){
                            downloadPdf(data, `${t('GENERIC.TEXT.INCIDENTS')}_${value === 'infractions' ? t('GENERIC.TEXT.INFRACTIONS') : t('GENERIC.TEXT.INCONSISTENCIES')}.pdf`)
                        }

                        setButtonsLoading(false);
                    };

                    downloadIncidentsPdf(value, {}, { ...filters, 'resultTypeKey': resultTypeKey }, callbackDownloadPdf);
                }catch(e){}
                break;
            case 'downloadCsv':
                try{
                    setButtonsLoading(true);
                    const callbackDownloadCsv = ({ status, data }: {status: string, data: any} ) => {
                        if(status === 'success'){
                            downloadCsv(data, `${t('GENERIC.TEXT.INCIDENTS')}_${value === 'infractions' ? t('GENERIC.TEXT.INFRACTIONS') : t('GENERIC.TEXT.INCONSISTENCIES')}.csv`)
                        }

                        setButtonsLoading(false);
                    };

                    downloadIncidentsCsv(value, {}, { ...filters, 'resultTypeKey': resultTypeKey }, callbackDownloadCsv);
                }catch(e){}
                break;
        }
    };

    const handleEventPreventDefault = (event: React.ChangeEvent<HTMLInputElement>, name: string, value: string) => {
        if(event.preventDefault instanceof Function){
            event.preventDefault();
        }

        if(event.stopPropagation instanceof Function){
            event.stopPropagation();
        }

        if(handleFiltersChange instanceof Function){
            handleFiltersChange({ name, value });
        }
    };

    const warningChart = () => {
        return (
            <ul style={{fontSize: '10px', marginTop: '20px'}}>
                <li>{t('OZYMANDIAS.DASHBOARD.INFORMATION_ABOUT_POSITIONING_CURSOR',undefined,'+','-')}</li>
                <li>{t('OZYMANDIAS.DASHBOARD.POSITIVE_REPRESENTS',undefined,'+')}</li>
                <li>{t('OZYMANDIAS.DASHBOARD.NEGATIVE_REPRESENTS',undefined,'-')}</li>
            </ul>
        );
    };

    const renderSliderTopIncidents = () => {
        let employeeIncidents:any = [];

        try{
            employeeIncidents = getAuditPerType()!.employeeIncidents;
            employeeIncidents.sort(sortByKeys(['-amountOfInfractions', '-amountOfInconsistencies', 'employeeName']))
        }catch(e){
            employeeIncidents = [];
        }
		
		if(employeeIncidents.length> 0){
			return (
				<Slider>
					{employeeIncidents.map((employee: EmployeeModel, index: number) => {
						return <AvatarNameValue key={index} size="s"
							image={`${URL_MICRO_FRONT}${imageUser}`}
							text={abbreviation(employee.employeeName, 24)}
							subText={abbreviation(employee.employeeOccupation, 24)}
							value={`${employee.amountOfInfractions} ${t('OZYMANDIAS.DASHBOARD.INFRACTION')}`}
							valueColor='#BB5641'
							currentValue={`${employee.amountOfInconsistencies} ${t('OZYMANDIAS.DASHBOARD.INCONSISTENCY')}`}
							handleClick={sliderHandleClick}
							handleClickValue={{ employeeId: employee.employeeId, employeeName: employee.employeeName, employeePis: employee.employeePis }}
							//currentValueColor="blue"
						/>
					})}
				</Slider>
			);
		}else{
			return (
				<Container style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					fontSize:'14px'}}>
						{t('OZYMANDIAS.DASHBOARD.NO_EMPLOYEE')}
				</Container>
			);
		}
    };

    const renderChartInfractionsPerDay = () => {
        let dataChart: (any[] | null | undefined)[] = [];

        try{
            Object.keys(getAuditPerType()!.infractionsPerDay).forEach(key => {
                dataChart.push([
                    formatLocalDate(key,true,'YYYY-MM-DD','DD/MM'),
                    getAuditPerType()!.infractionsPerDay[key],
                    auditorAnalytics!.metaInfraction
                ])
            });
        }catch(e){
            dataChart = [];
        }

        dataChart.sort(sortByKeys(['0'], (a:any, b: any) => { 
            return formatLocalDate(b, true, 'DD/MM', 'MM-DD');
        }));

        return (
            <ChartBar
                colorBar="#064FA9"
                colorLineDivergence="#E87A49"
                colorBarTextDivergence="#1F968F"
                colorBarText="#E87A49"
                height={400}
                showAxisX
                showAxisY
                showGrid
                isInteger
                showDataLine
                legendValues={[t('GENERIC.TEXT.INFRACTIONS'), t('OZYMANDIAS.SETTINGS.GOALS')]}
                legendPosition='right'
                rounded
                data={dataChart} />
        );
    };

    const renderChartAbsenteeismPerDay = () => {
        let dataChart: (any[] | null | undefined)[] = [];

        try{
            Object.keys(getAuditPerType()!.absenteeismPerDay).forEach(key => {
                dataChart.push([
                    formatLocalDate(key, true, 'YYYY-MM-DD','DD/MM'),
                    getAuditPerType()!.absenteeismPerDay[key],
                    auditorAnalytics!.metaAbsenteeism
                ])
            });
        }catch(e){
            dataChart = [];
        }

        dataChart.sort(sortByKeys(['0'], (a: any, b: any) => { 
            return formatLocalDate(b, true, 'DD/MM', 'MM-DD');
        }));

        return (
            <ChartBar
                colorBar="#1F968F"
                colorLineDivergence="#064FA9"
                colorBarTextDivergence="#1F968F"
                colorBarText="#064FA9"
                height={400}
                showAxisX
                showAxisY
                showGrid
                showDataLine
                legendValues={[t('GENERIC.TEXT.ABSENTEEISM'), t('OZYMANDIAS.SETTINGS.GOALS')]}
                legendPosition='right'
                rounded
                formatXAxis={convertMinutesToTimeStr}
                formatYAxis={convertMinutesToTimeStr}
                data={ dataChart} />
        );
    };

    const renderChartOvertimePerDay = () => {
        let dataChart: (any[] | null | undefined)[] = [];
        try{
            Object.keys(getAuditPerType()!.overtimePerDay).forEach(key => {
                dataChart.push([
                    formatLocalDate(key, true, 'YYYY-MM-DD', 'DD/MM'),
                    getAuditPerType()!.overtimePerDay[key],
                    auditorAnalytics!.metaOvertime
                ])
            });
        }catch(e){
            dataChart = [];
        }

        dataChart.sort(sortByKeys(['0'], (a:any, b: any) => { 
            return formatLocalDate(b, true, 'DD/MM', 'MM-DD');
        }));
        
        return (

            <ChartBar
                colorBar="#E87A49"
                colorLineDivergence="#E87A49"
                colorBarTextDivergence="#1F968F"
                colorBarText="#E87A49"
                height={400}
                showAxisX
                showAxisY
                showGrid
                showDataLine
                legendValues={[t('GENERIC.TEXT.OVERTIME'), t('OZYMANDIAS.SETTINGS.GOALS')]}
                legendPosition='right'
                rounded
                formatXAxis={convertMinutesToTimeStr}
                formatYAxis={convertMinutesToTimeStr}
                data={dataChart} />
        );
    };

    const renderChartDatabasePerDay = () => {
        let dataChart: (any[] | null | undefined)[] = [];

        try{
            Object.keys(getAuditPerType()!.databasePerDay).forEach(key => {
                dataChart.push([
                    formatLocalDate(key, true, 'YYYY-MM-DD','DD/MM'),
                    getAuditPerType()!.databasePerDay[key],
                    auditorAnalytics!.metaDatabase
                ])
            });
        }catch(e){
            dataChart = [];
        }

        dataChart.sort(sortByKeys(['0'], (a:any, b: any) => { 
            return formatLocalDate(b, true, 'DD/MM', 'MM-DD');
        }));

        return (
            <ChartBar
                colorBar="#F0AD4E"
                colorLineDivergence="#F0AD4E"
                colorBarTextDivergence="#1F968F"
                colorBarText="#F0AD4E"
                height={400}
                showAxisX
                showAxisY
                showGrid
                showDataLine
                legendValues={[t('GENERIC.TEXT.HOUR_BANK'), t('OZYMANDIAS.SETTINGS.GOALS')]}
                legendPosition='right'
                rounded
                formatXAxis={convertMinutesToTimeStr}
                formatYAxis={convertMinutesToTimeStr}
                data={dataChart} />
        );
    };

    const renderMetaOvertime = () =>{
        let totalMinutesOvertime = 0;
		let numberofMonths = 12;
		if(getAuditPerType()?.overtimePerDay){
			numberofMonths = Object.keys(getAuditPerType()!.overtimePerDay).length;
			Object.keys(getAuditPerType()!.overtimePerDay).forEach(key => {
				totalMinutesOvertime += (getAuditPerType()!.overtimePerDay[key]);
			});
		}

        return (
            <BoxMeta text={t('OZYMANDIAS.DASHBOARD.DAILY_AVERAGE')}
                atualValue={Math.floor(totalMinutesOvertime/numberofMonths)}
                metaValue={auditorAnalytics!.metaOvertime ? auditorAnalytics!.metaOvertime: 0}
                base={t('GENERIC.TEXT.HOURS')}
                format={(number) => {
					return number?convertMinutesToTimeStr(number):'00:00';
				}} />
        );
    };

    const renderMetaDatabase = () =>{
        let totalMinutesDatabase = 0;
		let numberofMonths = 12;
		if(getAuditPerType()?.databasePerDay){
        	numberofMonths = Object.keys(getAuditPerType()!.databasePerDay).length;
			Object.keys(getAuditPerType()!.databasePerDay).forEach(key => {
				totalMinutesDatabase += (getAuditPerType()!.databasePerDay[key]);
			});
		}
        return (
            <BoxMeta text={t('OZYMANDIAS.DASHBOARD.DAILY_AVERAGE')}
                atualValue={Math.floor(totalMinutesDatabase/numberofMonths)}
                metaValue={auditorAnalytics!.metaDatabase?auditorAnalytics!.metaDatabase:0}
                base={t('GENERIC.TEXT.HOURS')}
                format={(number) => {
					return number?convertMinutesToTimeStr(number):'00:00';
				}} />
        );
    };

    const renderMetaAbsenteeism = () =>{
        let totalMinutesAbsenteeism = 0;
		let numberofMonths = 12;
		if(getAuditPerType()?.absenteeismPerDay){
			 numberofMonths = Object.keys(getAuditPerType()!.absenteeismPerDay).length;
			Object.keys(getAuditPerType()!.absenteeismPerDay).forEach(key => {
				totalMinutesAbsenteeism += (getAuditPerType()!.absenteeismPerDay[key]);
			});
		}

        return (
            <BoxMeta text={t('OZYMANDIAS.DASHBOARD.DAILY_AVERAGE')}
                atualValue={Math.floor(totalMinutesAbsenteeism/numberofMonths)}
                metaValue={auditorAnalytics!.metaAbsenteeism? auditorAnalytics!.metaAbsenteeism: 0}
                base={t('GENERIC.TEXT.HOURS')}
                format={(number) => {
					return number?convertMinutesToTimeStr(number):'00:00';
				}} />
        );
    };

    const renderMetaInfraction = () =>{
        let totalInfractions = 0;
		let numberofMonths = 12;
		if(getAuditPerType()?.infractionsPerDay){
			numberofMonths = Object.keys(getAuditPerType()!.infractionsPerDay).length;
			Object.keys(getAuditPerType()!.infractionsPerDay).forEach(key => {
				totalInfractions += getAuditPerType()!.infractionsPerDay[key];
			});
		}

        return (
            <BoxMeta text={t('OZYMANDIAS.DASHBOARD.DAILY_AVERAGE')}
                atualValue={(totalInfractions/numberofMonths)}
                metaValue={auditorAnalytics!.metaInfraction ? auditorAnalytics!.metaInfraction: 0}
                base={t('GENERIC.TEXT.INFRACTIONS')}
                format={(number)=> {
					return number?number.toFixed(0):0;
				}} />
        );
    };

    const rendChartInfractionsPerCategory = () => {
        let dataChart: any[] = [];

        try{
            Object.keys(getAuditPerType()!.infractionsPerCategory).forEach(key => {
                dataChart.push({
                    "name": String(key),
                    value: getAuditPerType()!.infractionsPerCategory[key]
                });
            });
        }catch(e){
            dataChart = [];
        }

        dataChart.sort(sortByKeys(['-name']))

        return (
            <ChartPie
				colors={colorChart}
                legendPosition="left"
				data={dataChart}
            />
        );
    };

    const rendChartInconsistenciesPerCategory = () => {
        let dataChart: any[] = [];

        try{
            Object.keys(getAuditPerType()!.inconsistenciesPerCategory).forEach(key => {
                dataChart.push({
                    "name": String(key),
                    value: getAuditPerType()!.inconsistenciesPerCategory[key]
                });
            });
        }catch(e){
            dataChart = [];
        }

        return(
            <ChartPie
				colors={colorChart}
                legendPosition="left"
				data={dataChart}
            />
        );
    }

    const rendBarIncidentsAndInfractions = () => {
        const currentIncidents = getAuditPerType()!.incidents;
		if(currentIncidents){
			let numberOfInconsistencies = currentIncidents.inconsistencies.length;
			let numberOfInfractions = currentIncidents.infractions?.length;

			return(
				<BarIndicator maxValue={numberOfInconsistencies + numberOfInfractions}>
					<BarIndicator.Item colorBar={colorChart[0]} value={numberOfInfractions} title={t('OZYMANDIAS.DASHBOARD.NUMBER_OF_INFRACTIONS')}/>
					<BarIndicator.Item colorBar={colorChart[1]} value={numberOfInconsistencies} title={t('OZYMANDIAS.DASHBOARD.NUMBER_OF_INCONSISTENCES')}/>
				</BarIndicator>
			);
		}else{
			return <></>
		}
    }

    const buildAbstractYearInfractionsForList = () => {

        let listOfabstractInfractionsOfYear: { [key: string]: string; }[]= [];

        Object.keys(auditorAnalyticsYear?.[resultTypeKey]?.infractionsYearPerMonthPerCategory).forEach(key => {
			const infractionsYearPerMonth = auditorAnalyticsYear?.[resultTypeKey]?.infractionsYearPerMonthPerCategory[key];
			let objectAbstract: {[key: string]: string} = {month: key};

			Object.keys(infractionsYearPerMonth).forEach(infraction => {
				const value = infractionsYearPerMonth[infraction];
				objectAbstract[infraction] = value==null?0:value;
			})

            listOfabstractInfractionsOfYear.push(objectAbstract);

        });
        listOfabstractInfractionsOfYear.sort(sortAlphabeticallyByKey('month'));

        return listOfabstractInfractionsOfYear;
    };

    const buildAbstractYearInconsistenciesForList = () => {

        let listOfabstractInconsistenciesOfYear: { [key: string]: string; }[]= [];

        Object.keys(auditorAnalyticsYear?.[resultTypeKey]?.inconsistenciesYearPerMonthPerCategory).forEach(key => {

			const inconsistenciesYearPerMonth = auditorAnalyticsYear?.[resultTypeKey]?.inconsistenciesYearPerMonthPerCategory[key];
			let objectAbstract: {[key: string]: string} = {month: key};

			Object.keys(inconsistenciesYearPerMonth).forEach(inconsistency => {
				const value = inconsistenciesYearPerMonth[inconsistency];
				objectAbstract[inconsistency] = value==null?0:value;
			})
            listOfabstractInconsistenciesOfYear.push(objectAbstract);

        });

        listOfabstractInconsistenciesOfYear.sort(sortAlphabeticallyByKey('month'));

        return listOfabstractInconsistenciesOfYear;
    };

    const renderChartInconsistenciesYearPerCategory = () => {
        let dataChart: any[] = [];
		let dataChartLegend = [];
		let inconsistenciesTypes = auditorAnalytics?.inconsistencyTypes;

        Object.keys(auditorAnalyticsYear?.[resultTypeKey]?.inconsistenciesYearPerMonthPerCategory).forEach(key => {
            let month = [ moment(key, ['YYYY-MM']).format('MM/YY') ];
			const inconsistenciesYearPerMonth = auditorAnalyticsYear?.[resultTypeKey]?.inconsistenciesYearPerMonthPerCategory[key];
			inconsistenciesTypes!.forEach(inconsistency => {
				const value = inconsistenciesYearPerMonth[inconsistency];
			
				month.push(value==null?0:value);

			})

            dataChart.push(month);
        });


		let legend = inconsistenciesTypes;

        dataChart.sort(sortAlphabeticallyByKey('0'));

		dataChartLegend.push(legend);
		dataChartLegend.push(...dataChart);

        return (
            <ChartMultiBar
                colors={colorChart}
                height={310}
                showAxisX
                showAxisY
                rounded
                sizeRounded={6}
                data={
                    dataChartLegend
                }
                legendValues={legend}
                legendPosition="right" 
				axisXLegendRotation={-60}
                legendColumnsSize={4} />
        );
    };

    const renderChartInfractionsYearPerCategory = () => {
        let dataChart: any[] = [];
		let dataChartLegend = [];
		let infractionsTypes = auditorAnalytics?.infractionTypes;
        Object.keys(auditorAnalyticsYear?.[resultTypeKey]?.infractionsYearPerMonthPerCategory).forEach(key => {
            let month = [ moment(key, ['YYYY-MM']).format('MM/YY') ];
			const infractionsYearPerMonth = auditorAnalyticsYear?.[resultTypeKey]?.infractionsYearPerMonthPerCategory[key];
			infractionsTypes!.forEach(infraction => {
				const value = infractionsYearPerMonth[infraction];
				month.push(value==null?0:value);
			})

            dataChart.push(month);
        });

		let legend =infractionsTypes;

        dataChart.sort(sortAlphabeticallyByKey('0'));

		dataChartLegend.push(legend);
		dataChartLegend.push(...dataChart);

        return (
            <ChartMultiBar
                colors={colorChart}
                height={310}
                showAxisX
                showAxisY
                rounded
                sizeRounded={6}
                data={
                    dataChartLegend
                }
                legendValues={legend}
                legendPosition="right"
				axisXLegendRotation={-60}
                legendColumnsSize={2} />
        );
    };

	return(
		<Container className={styles['audit-dashboard-rt']}>
            <PageTitle.Small title={t('OZYMANDIAS.DASHBOARD.INCIDENT_ANALYSIS')}/>
            {(currentStatus === 'waiting' || forcePageLoading) &&
				<Row.Item lg={12} md={12} sm={12}>
					<Panel defaul>
						<Loading text={t('OZYMANDIAS.DASHBOARD.LOADING_ANALYSIS')}/>
					</Panel>
				</Row.Item>
			}
			
            {currentStatus === 'error' &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Panel centeredContent>
                            <h4>{t('OZYMANDIAS.SETTINGS.PROBLEM_RETURNING_INFORMATION')}</h4>
                        </Panel>
                    </Row.Item>
                </Row>
			}

            {(currentStatus === 'success' && !forcePageLoading) &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12} xs={12}>
                        <Container className={`${styles['audit-dashboard-filters']} ${process.env.NODE_ENV!=='development'?styles['not-development']:''}`}>

                            <Select name="selectResultType" medium inline primary
                                value={(resultTypeKey === 'resultsManaged' ? t('CABLE.POINT_INCIDENT.PLANNED') : t('OZYMANDIAS.DASHBOARD.CONTRACTED'))}
                                options={[
                                    { key: 'resultsContract', value: t('OZYMANDIAS.DASHBOARD.CONTRACT_VS_PERFORMED')},
                                    { key: 'resultsManaged', value: t('OZYMANDIAS.DASHBOARD.PLANNED_VS_PERFORMED')}
                                ]}
                                handleChange={(value) => {
                                    if(resultTypeKey !== value){
                                        setForcePageLoading(true)
                                        setResultTypeKey((value === 'resultsManaged' ? ResultTypeEnum.managed : ResultTypeEnum.contract));
                                        setTimeout(() => {
                                            setForcePageLoading(false)
                                        }, 500);
                                    }
                                }} />

                            <Select medium inline multiple primary
                                value={selectFilters.idValue}
                                options={selectFilters.idOptions || []}
                                selectedItemNameLength={15}
                                selectItemsToShowLength={1}
                                handleChange={(value) => handleFiltersChange({ name: 'selectedId', value: value })}
                                name="selectEmployee"
                                handleChanges={handleFiltersChange} />

                            <Select medium inline multiple primary
                                value={selectFilters.occupationValue}
                                options={selectFilters.occupationOptions || []}
                                selectedItemNameLength={15}
                                selectItemsToShowLength={1}
                                handleChange={(value) => handleFiltersChange({ name: 'selectedOccupation', value: value })}
                                name="selectOccupation"
							handleChanges={handleFiltersChange} />
                        </Container>

                        <Tabs activeTab={currentActiveTabIndex} handleTabChange={(event) => setCurrentActiveTabIndex(event.activeTabIndex)}>
                            <Tabs.Item title='Dashboard'>
                                <Row className={styles['row-dashboard']}>
                                    <Row.Item lg={9} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Panel className="panel-employees-slider" defaul
                                                    title={t('OZYMANDIAS.DASHBOARD.EMPLOYEES_BIGGEST_INFRINGEMENTS')}
                                                    subTitle={dataInformation}>
													<>
                                                    	{renderSliderTopIncidents()}
													</>
                                                </Panel>
                                            </Row.Item>
                                            {/* <Row.Item lg={6} md={6} sm={6} xs={6}>
                                                <BoxInformation
                                                    title="Passivo oculto"
                                                    value={currencyFormat(auditorAnalytics.abstract.liabilities.value, 'currency')}
                                                    smallValue={auditorAnalytics.abstract.liabilities.previous}
                                                    smallValueDown={auditorAnalytics.abstract.liabilities.situation==='down'}
                                                    smallValueUp={auditorAnalytics.abstract.liabilities.situation==='up'}
                                                    icon="dollar-sign"
                                                    danger
                                                    moreInformation="Passivo oculto"/>
                                            </Row.Item>
                                            */}
                                            <Row.Item lg={8} md={8} sm={8} xs={8}>
                                                <Row>
                                                    <Row.Item
                                                        lg={auditorAnalytics!.configCalculationType==='DATABASE'?6:6}
                                                        md={auditorAnalytics!.configCalculationType==='DATABASE'?6:6}
                                                        sm={auditorAnalytics!.configCalculationType==='DATABASE'?6:6}
                                                        xs={auditorAnalytics!.configCalculationType==='DATABASE'?6:6}>
                                                        <BoxInformation
                                                            title={auditorAnalytics!.configCalculationType !=='DATABASE' ? t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME') : t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME_EXCEEDING_ALLOWED',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit))}
                                                            value={convertMinutesToTimeStr(getAuditPerType()!.abstract?.overtime?.value)+'h'}
                                                            //smallValue={auditorAnalytics.abstract.overtime.previous}
                                                            //smallValueDown={auditorAnalytics.abstract.overtime.situation==='down'}
                                                            //smallValueUp={auditorAnalytics.abstract.overtime.situation==='up'}
                                                            icon="clock"
                                                            detail={() => setModalOvertime({show: true, name: dataInformation})}
                                                            small/>
                                                    </Row.Item>

                                                    {auditorAnalytics!.configCalculationType==='DATABASE' &&
                                                        <Row.Item lg={6} md={6} sm={6} xs={6}>
                                                            <BoxInformation
                                                                title={auditorAnalytics!.configCalculationType !=='DATABASE' ? t('OZYMANDIAS.DASHBOARD.TOTAL_BANK_OF_HOURS') :t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME_LESS_ALLOWED',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit))}
                                                                value={convertMinutesToTimeStr(getAuditPerType()!.abstract.database?.value || 0)+'h'}
                                                                //smallValue={auditorAnalytics.abstract.database?.previous}
                                                                //smallValueDown={auditorAnalytics.abstract.database?.situation==='down'}
                                                                //smallValueUp={auditorAnalytics.abstract.database?.situation==='up'}
                                                                icon="clock"
                                                                detail={() => setModalDatabase({show: true, name: dataInformation})}
                                                                small/>
                                                        </Row.Item>
                                                    }

                                                    <Row.Item
                                                        lg={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        md={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        sm={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        xs={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}>
                                                        <BoxInformation
                                                            title={t('GENERIC.TEXT.ABSENTEEISM')}
                                                            value={getAuditPerType()!.abstract?.absenteeism?.value+'%'}
                                                            //smallValue={auditorAnalytics.abstract.absenteeism.previous}
                                                            //smallValueDown={auditorAnalytics.abstract.absenteeism.situation==='down'}
                                                            //smallValueUp={auditorAnalytics.abstract.absenteeism.situation==='up'}
                                                            icon="user-slash"
                                                            small/>
                                                    </Row.Item>
                                                    <Row.Item
                                                        lg={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        md={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        sm={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        xs={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}>
                                                        <BoxInformation
                                                            title={`${t('GENERIC.TEXT.OVERTIME')} / ${t('GENERIC.TEXT.ABSENTEEISM')}`}
                                                            value={convertMinutesToTimeStr(getAuditPerType()!.abstract?.overtimeAbsenteeism.value)+'h'}
                                                            //smallValue={auditorAnalytics.abstract.overtimeAbsenteeism.previous}
                                                            //smallValueDown={auditorAnalytics.abstract.overtimeAbsenteeism.situation==='down'}
                                                            //smallValueUp={auditorAnalytics.abstract.overtimeAbsenteeism.situation==='up'}
                                                            icon="user-clock"
                                                            small/>
                                                    </Row.Item>
                                                    <Row.Item
                                                        lg={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        md={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        sm={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}
                                                        xs={auditorAnalytics!.configCalculationType==='DATABASE'?4:6}>
                                                        <BoxInformation
                                                            title={t('OZYMANDIAS.DASHBOARD.ABSENTEEISM_HOURS')}
                                                            value={convertMinutesToTimeStr(getAuditPerType()!.abstract?.hoursAbsenteeism.value)+'h'}
                                                            //smallValue={auditorAnalytics.abstract.hoursAbsenteeism.previous}
                                                            //smallValueDown={auditorAnalytics.abstract.hoursAbsenteeism.situation==='down'}
                                                            //smallValueUp={auditorAnalytics.abstract.hoursAbsenteeism.situation==='up'}
                                                            icon="user-slash"
                                                            detail={() => setModalAbsenteeism({show:true, name: dataInformation})}
                                                            small/>
                                                    </Row.Item>
                                                </Row>
                                            </Row.Item>
                                            <Row.Item lg={4} md={4} sm={4} xs={4}>
                                                <Panel height={225} title={t('OZYMANDIAS.DASHBOARD.TOTAL_OF_INCIDENTS')} subTitle={dataInformation} className={`${styles['panel']} ${styles['panel-total-incidents']}`}>
                                                    {rendBarIncidentsAndInfractions()}
                                                </Panel>
                                            </Row.Item>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Row>
                                                    <Row.Item lg={6} md={6} sm={6} xs={12}>
                                                        <Panel title={t('OZYMANDIAS.DASHBOARD.SUMMARY_INFRINGEMENTS_CATEGORY')} 
                                                            subTitle={dataInformation} 
                                                            className={styles['panel']}>
                                                            <>	

                                                                {!isObjectEmpty(getAuditPerType()!.infractionsPerCategory)  && rendChartInfractionsPerCategory()}
                                                                {isObjectEmpty(getAuditPerType()!.infractionsPerCategory) &&
                                                                    <Container style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        height: '200px',
                                                                        fontSize:'14px'}}>
                                                                            {t('OZYMANDIAS.DASHBOARD.NO_INFRINGEMENTS')}
                                                                    </Container>
                                                                }
                                                            </>
                                                        </Panel>
                                                    </Row.Item>
                                                    <Row.Item lg={6} md={6} sm={6} xs={12}>
                                                        <Panel title={t('OZYMANDIAS.DASHBOARD.SUMMARY_INCONSISTENCIES_CATEGORY')} 
                                                            subTitle={dataInformation} 
                                                            className={styles['panel']}>
                                                            <>
                                                                {!isObjectEmpty(getAuditPerType()!.inconsistenciesPerCategory) && rendChartInconsistenciesPerCategory()}
                                                                {isObjectEmpty(getAuditPerType()!.inconsistenciesPerCategory) &&
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        height: '200px',
                                                                        fontSize:'14px'}}>
                                                                            {t('OZYMANDIAS.DASHBOARD.NO_INCONSISTENCIES')}
                                                                    </div>
                                                                }
                                                            </>
                                                        </Panel>
                                                    </Row.Item>
                                                </Row>
                                            </Row.Item>
                                        </Row>
                                        <Row>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>

                                                <PageTitle.Small title={t('OZYMANDIAS.DASHBOARD.DAILY_EVOLUTION_IN_MONTH', undefined, getMonthHumanized(filters.startDate,(navigator.language || 'pt-br')))} />
                                            </Row.Item>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Panel title={auditorAnalytics!.configCalculationType !=='DATABASE' ? t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME') : t('OZYMANDIAS.DASHBOARD.EVOLUTION_OVERTIME_EXCEEDING',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit))} subTitle={t('OZYMANDIAS.DASHBOARD.DAILY_WORKLOAD')} className={styles['panel']}>
                                                    <Row className={styles['chart-container']}>
                                                        <Row.Item lg={10} md={10} sm={10} xs={10} style={{paddingRight: 0}}>
                                                            {renderChartOvertimePerDay()}
                                                            {warningChart()}
                                                        </Row.Item>
                                                        <Row.Item lg={2} md={2} sm={2} xs={2}>
                                                            {renderMetaOvertime()}
                                                        </Row.Item>
                                                    </Row>
                                                </Panel>
                                            </Row.Item>
                                        </Row>
                                        <Row>
                                        {auditorAnalytics!.configCalculationType ==='DATABASE' && <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Panel title={t('OZYMANDIAS.DASHBOARD.EVOLUTION_OVERTIME_LESS',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit))} subTitle={t('OZYMANDIAS.DASHBOARD.DAILY_WORKLOAD')} className={styles['panel']}>
                                                    <Row>
                                                        <Row.Item lg={10} md={10} sm={10} xs={10} style={{paddingRight: 0}}>
                                                            {renderChartDatabasePerDay()}
                                                            {warningChart()}
                                                        </Row.Item>
                                                        <Row.Item lg={2} md={2} sm={2} xs={2}>
                                                            {renderMetaDatabase()}
                                                        </Row.Item>
                                                    </Row>
                                                </Panel>
                                            </Row.Item>
                                        }
                                        </Row>
                                        <Row className={styles['chart-container']}>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Panel title={t('OZYMANDIAS.DASHBOARD.ABSENTEEISM_PROGRESS')} subTitle={t('OZYMANDIAS.DASHBOARD.NUMBER_OF_ABSENTEEISM_DAILY_HOURS')} className={styles['panel']}>
                                                    <Row>
                                                        <Row.Item lg={10} md={10} sm={10} xs={10} style={{paddingRight: 0}}>
                                                            {renderChartAbsenteeismPerDay()}
                                                            {warningChart()}
                                                        </Row.Item>
                                                        <Row.Item lg={2} md={2} sm={2} xs={2}>
                                                            {renderMetaAbsenteeism()}
                                                        </Row.Item>
                                                    </Row>
                                                </Panel>
                                            </Row.Item>
                                        </Row>
                                        <Row>
                                            <Row.Item lg={12} md={12} sm={12} xs={12}>
                                                <Panel title={t('OZYMANDIAS.DASHBOARD.SUMMARY_OF_INFRINGEMENTS')} subTitle={t('OZYMANDIAS.DASHBOARD.DAILY_NUMBER_OF_INFRINGEMENTS')} className={styles['panel']}>
                                                    <Row className={styles['chart-container']}>
                                                        <Row.Item lg={10} md={10} sm={10} xs={10} style={{paddingRight: 0}}>
                                                            {renderChartInfractionsPerDay()}
                                                            {warningChart()}
                                                        </Row.Item>
                                                        <Row.Item lg={2} md={2} sm={2} xs={2}>
                                                            {renderMetaInfraction()}
                                                        </Row.Item>
                                                    </Row>
                                                </Panel>
                                            </Row.Item>
                                        </Row>
                                    </Row.Item>
                                    <Row.Item lg={3} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Row.Item lg={12} md={12} sm={12} xs={12} className={styles['hide-small']}>
                                                <AlertTiny
                                                    warn
                                                    icon="exclamation-triangle"
                                                    title={t('GENERIC.TEXT.SETTINGS')}
                                                    text={t('OZYMANDIAS.DASHBOARD.SETTINGS_INFO')}/>
                                            </Row.Item>

                                            <Row.Item lg={12} md={12} sm={12} xs={12} className={styles['margin-botton-10']}>
												<Calendar 
													showCalendar range primary
													dates={{startDate:filters.startDate, endDate: filters.endDate}}
													// dateFormatter='DD/MM/YYYY' 
													handleDateChange={(data:any) => handleFiltersChange({name: data.name, value: data.dates})}/>
                                            </Row.Item>

                                            <Row.Item lg={12} md={12} sm={12} xs={12} className={styles['hide-small']}>
                                                <Panel className={styles['panel-alert-messages']}>
                                                    <>
                                                        <BoxMessage className={styles['box-message-legal-terms']} title={t('OZYMANDIAS.DASHBOARD.TERMS_USED')} subTitle={t('OZYMANDIAS.DASHBOARD.LEGAL_TERMS')} icon="bell" warn success={false}>
                                                            <BoxMessage.Item><strong>Mais de 6 dias consecutivos</strong> - descanso semanal remunerado (desrespeito ao Art. 67 da CLT e Lei n° 605/49 (Art. 1° e 4°).</BoxMessage.Item>
                                                            <BoxMessage.Item>
                                                                <strong>Folgas aos domingos: </strong>
                                                                <Container>
                                                                    2x1 - Trabalha até dois domingos consecutivos e folga no terceiro domingo (Lei nº 11.603/07)
                                                                </Container>
                                                                <Container>
                                                                    6x1 - Trabalha até seis domingos consecutivos e folga no sétimo domingo (Decreto 27.048/49)
                                                                </Container>
                                                                </BoxMessage.Item>
                                                            <BoxMessage.Item><strong>Interjornada</strong> - não observar limite de 11 horas entre uma jornada e outra (desrespeito ao Art. 66 da CLT).</BoxMessage.Item>
                                                            <BoxMessage.Item><strong>Horas extras indevidas</strong> - Exceder o limite de 2 horas extras ao dia (desrespeito ao Art. 59 da CLT com ressalvas dispostas no Art. 61 da CLT).</BoxMessage.Item>
                                                            <BoxMessage.Item><strong>Problema de intervalo</strong> - Período de intervalo indevido (desrespeito ao Art. 71 da CLT).</BoxMessage.Item>
                                                            <BoxMessage.Item><strong>{t('GENERIC.TEXT.OVERTIME')}</strong> - {t('OZYMANDIAS.DASHBOARD.EXCEEDING_PLANNED_DAILY_WORKLOAD')}</BoxMessage.Item>
                                                            <BoxMessage.Item><strong>{t('GENERIC.TEXT.ABSENTEEISM')}</strong> - {t('OZYMANDIAS.DASHBOARD.SUM_OF_PERIODS_OF_ABSENCE')}</BoxMessage.Item>
                                                        </BoxMessage>

                                                        <BoxMessage title={t('OZYMANDIAS.DASHBOARD.CALCULATIONS')} subTitle={t('OZYMANDIAS.DASHBOARD.HOW_WE_PERFORM_CALCULATIONS')} icon="calculator" success>
                                                            <BoxMessage.Item>
                                                                <strong>{t('GENERIC.TEXT.OVERTIME')} </strong> - {t('OZYMANDIAS.DASHBOARD.OVERTIME_DESCRIPTION')}
                                                            </BoxMessage.Item>
                                                            <BoxMessage.Item>
                                                                <strong>{t('GENERIC.TEXT.ABSENTEEISM')}</strong> - {t('OZYMANDIAS.DASHBOARD.ABSENTEEISM_DESCRIPTION')}
                                                            </BoxMessage.Item>
                                                            <BoxMessage.Item>
                                                                <strong>{t('GENERIC.TEXT.OVERTIME')} / {t('GENERIC.TEXT.ABSENTEEISM')}</strong> - {t('OZYMANDIAS.DASHBOARD.OVERTIME_SAME_DAY_ABSENTEEISM')}
                                                            </BoxMessage.Item>

                                                        </BoxMessage>
                                                    </>
                                                </Panel>
                                            </Row.Item>
                                        </Row>
                                    </Row.Item>
                                </Row>
                            </Tabs.Item>

                            <Tabs.Item title={t('GENERIC.TEXT.INFRACTIONS')}>
                                <Row>
                                    <Row.Item lg={12} md={12} sm={12} xs={12}>
                                        <Panel title={t('GENERIC.TEXT.INFRACTIONS')} className={`${styles['panel-incidents']} clearfix`}
                                            actions={[{
                                                primary: true, icon: 'file-pdf', loading: buttonsLoading, title:t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS',undefined,'PDF'), disabled: false,
                                                action: (event: React.ChangeEvent<HTMLInputElement>) => handleEventPreventDefault(event, 'downloadPdf', 'infractions')
                                            },
                                            {
                                                primary: true, icon: 'file-csv', loading: buttonsLoading, title: t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS',undefined,'CSV'), disabled: false,
                                                action: (event: React.ChangeEvent<HTMLInputElement>) => handleEventPreventDefault(event, 'downloadCsv', 'infractions')
                                            }]}
                                            noPadding>
                                            <TableListPagination list={getAuditPerType()!.incidents && getAuditPerType()!.incidents.infractions}
                                                    headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.INFRINGEMENT_TYPE'), t('OZYMANDIAS.DASHBOARD.INFRINGEMENT_GROUP'), t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.PLAN'), t('GENERIC.TEXT.LAW')]}
                                                    columns={['date', 'employeeName', 'incidentTypeName', 'incidentGroupName', 'details', 'scaleDetails', 'law']}
                                                    handleColumnRender={handleColumnRender} />
                                        </Panel>
                                    </Row.Item>
                                </Row>
                            </Tabs.Item>

                            <Tabs.Item title={t('GENERIC.TEXT.INCONSISTENCIES')}>
                                <Row>
                                    <Row.Item lg={12} md={12} sm={12} xs={12}>
                                        <Panel title={t('GENERIC.TEXT.INCONSISTENCIES')} className={`${styles['panel-incidents']} clearfix`}
                                            actions={[{
                                                primary: true, icon: 'file-pdf', loading: buttonsLoading, title: t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS',undefined,'PDF'), disabled: false, 
                                                action: (event: React.ChangeEvent<HTMLInputElement>) => handleEventPreventDefault(event, 'downloadPdf', 'inconsistencies')
                                            },
                                            {
                                                primary: true, icon: 'file-csv', loading: buttonsLoading, title: t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS',undefined,'CSV'), disabled: false, 
                                                action: (event: React.ChangeEvent<HTMLInputElement>) => handleEventPreventDefault(event, 'downloadCsv', 'inconsistencies')
                                            }]}
                                            noPadding>
                                            <TableListPagination list={getAuditPerType()!.incidents && getAuditPerType()!.incidents.inconsistencies}
                                                headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.INCONSISTENCY_TYPE'), t('OZYMANDIAS.DASHBOARD.INCONSISTENCY_GROUP'), t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.PLAN'), t('GENERIC.TEXT.LAW')]}
                                                columns={['date', 'employeeName', 'incidentTypeName', 'incidentGroupName', 'details', 'scaleDetails', 'law']}
                                                handleColumnRender={handleColumnRender} />
                                        </Panel>
                                    </Row.Item>
                                </Row>
                            </Tabs.Item>

                            <Tabs.Item title={t('OZYMANDIAS.DASHBOARD.INFRINGEMENT_PROGRESSION')}>
                                <Row className={styles['chart-container']}>
                                    <Row.Item lg={12} md={12} sm={12} xs={12}>
                                        <Panel title={t('OZYMANDIAS.DASHBOARD.MONTHLY_PROGRESSION_OF_INFRINGEMENTS')} className={`${styles['panel-hidden-liability']} ${styles['panel-infraction-year']} clearfix`} noPadding>
											<>
												<Row>
													<Row.Item lg={12} md={12} sm={12}>
														{auditorAnalyticsYear?.[resultTypeKey] && renderChartInfractionsYearPerCategory()}
													</Row.Item>
												</Row>
												{auditorAnalyticsYear?.[resultTypeKey] && <TableListPagination list={auditorAnalyticsYear?.[resultTypeKey] && buildAbstractYearInfractionsForList()}
													headers={[t('GENERIC.TEXT.MONTH')].concat(auditorAnalytics?.infractionTypes!)}
													columns={['month'].concat(auditorAnalytics?.infractionTypes!)}
													handleColumnRender={handleColumnRender} />
												}
											</>

                                        </Panel>
                                    </Row.Item>
                                </Row>
                            </Tabs.Item>

                            <Tabs.Item title={t('OZYMANDIAS.DASHBOARD.INCONSISTENCIES_PROGRESSION')}>
                                <Row className={styles['chart-container']}>
                                    <Row.Item lg={12} md={12} sm={12} xs={12}>
                                        <Panel title={t('OZYMANDIAS.DASHBOARD.MONTHLY_PROGRESSION_OF_INCONSISTENCIES')} className={`${styles['panel-hidden-liability']} ${styles['panel-inconsistency-year']} clearfix`} noPadding>
											<>
												<Row>
													<Row.Item lg={12} md={12} sm={12}>
														{auditorAnalyticsYear?.[resultTypeKey] && renderChartInconsistenciesYearPerCategory()}
													</Row.Item>
												</Row>
												<TableListPagination list={auditorAnalyticsYear?.[resultTypeKey] && buildAbstractYearInconsistenciesForList()}
													headers={[t('GENERIC.TEXT.MONTH')].concat(auditorAnalytics?.inconsistencyTypes!)}
													columns={['month'].concat(auditorAnalytics?.inconsistencyTypes!)}
													handleColumnRender={handleColumnRender} />
											</>
                                        </Panel>
                                    </Row.Item>
                                </Row>
                            </Tabs.Item>

                        </Tabs>
                    </Row.Item>
                </Row>
            }

            {modalIncidentsPerEmployee.show &&
                <Modal title={`Incidentes de ${modalIncidentsPerEmployee.employeeName} (pis: ${modalIncidentsPerEmployee.employeePis || ''})`}
                    bg showClose={true}
                    handleClose={() => setModalIncidentsPerEmployee({ show: false })}>

                    <Tabs activeTab={0}>

                        <Tabs.Item title={t('GENERIC.TEXT.INFRACTIONS')}>
                            <Panel title={t('GENERIC.TEXT.INFRACTIONS')} className="panel-incidents clearfix" noPadding>
                                <TableListPagination list={modalIncidentsPerEmployee.infractionsList}
                                    headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.INFRINGEMENT_TYPE'),  t('OZYMANDIAS.DASHBOARD.INFRINGEMENT_GROUP'),t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.PLAN'), t('GENERIC.TEXT.LAW')]}
                                    columns={['date', 'employeeName', 'incidentTypeName', 'incidentGroupName', 'details', 'scaleDetails', 'law']}
                                    handleColumnRender={handleColumnRender} />
                            </Panel>
                        </Tabs.Item>

                        <Tabs.Item title={t('GENERIC.TEXT.INCONSISTENCIES')}>
                            <Panel title={t('GENERIC.TEXT.INCONSISTENCIES')} className="panel-incidents clearfix" noPadding>
                                <TableListPagination list={modalIncidentsPerEmployee.inconsistenciesList}
                                    headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.INCONSISTENCY_TYPE'), t('OZYMANDIAS.DASHBOARD.INCONSISTENCY_GROUP'),t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.PLAN'), t('GENERIC.TEXT.LAW')]}
                                    columns={['date', 'employeeName', 'incidentTypeName', 'incidentGroupName', 'details', 'scaleDetails', 'law']}
                                    handleColumnRender={handleColumnRender} />
                            </Panel>
                        </Tabs.Item>

                    </Tabs>
                </Modal>
            }

            {modalOvertime.show &&
                <Modal title={auditorAnalytics!.configCalculationType !== 'DATABASE' ? t('GENERIC.TEXT.OVERTIME') : t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME_EXCEEDING_ALLOWED_IN',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit), modalOvertime.name)}
                    bg showClose={true} handleClose={() => setModalOvertime({ show: false })}>

                    <TableListPagination list={auditorAnalytics![resultTypeKey]?.['overtime']}
                        headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.NUMBER_OF_HOURS'), t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.SCALE')]}
                        columns={['date', 'employeeName', 'value', 'pointsDay', 'dailyScaleDay']}
                        className="overtime-list"
                        handleColumnRender={handleColumnRender} />
                </Modal>
            }

            {modalDatabase.show &&
                <Modal title={t('OZYMANDIAS.DASHBOARD.TOTAL_OVERTIME_LESS_ALLOWED_IN',undefined,convertMinutesToTimeStr(auditorAnalytics!.configOvertimeLimit),modalDatabase.name)}
                    bg showClose={true} handleClose={() => setModalDatabase({ show: false })}>

                    <TableListPagination list={getAuditPerType()!['database'] || []}
                        headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.NUMBER_OF_HOURS')]}
                        columns={['date', 'employeeName', 'value']}
                        className="database-list"
                        handleColumnRender={handleColumnRender} />
                </Modal>
            }

            {modalAbsenteeism.show &&
                <Modal title={t('OZYMANDIAS.DASHBOARD.ABSENTEEISM_HOURS_IN',undefined,modalAbsenteeism.name)} bg showClose={true} handleClose={() => setModalAbsenteeism({ show: false })}>
                    <TableListPagination list={getAuditPerType()!['absenteeism']}
                        headers={[t('GENERIC.TEXT.DATE'), t('GENERIC.TEXT.NAME'), t('OZYMANDIAS.DASHBOARD.NUMBER_OF_HOURS'), t('CABLE.POINT_REGISTRATION.TEXT.RECORDS'), t('GENERIC.TEXT.SCALE')]}
                        columns={['date', 'employeeName', 'value', 'pointsDay', 'dailyScaleDay']}
                        className="absenteeism-list"
                        handleColumnRender={handleColumnRender} />
                </Modal>
            }
		</Container>

	);
}

export default Dashboard;